<template>
  <div id="preload_wrap">
    <div class="loader" />
  </div>
</template>

<script>
export default {
  name: 'Logout',
  mounted() {
    this.logout();
  },
  methods: {
    logout() {
      this.$http
        .post('v1/user/logout', {
          access_token: window.localStorage['access-token'],
        })
        .then(
          () => {
            this.$store.dispatch('logout');
          },
          () => {
            this.$store.dispatch('logout');
          },
        );
    },
  },
};
</script>

<style scoped></style>
