<template>
  <div class="rb-page">
    <div class="container profile-container main-container">
      <div class="row no-gutters">
        <sidebar-profile :show="show" @closeSidebar="closeSidebar" />

        <router-view />
      </div>
    </div>
    <mail-modal v-if="sex.isMan || sex.isWoman" />
    <vue-gal />
    <letter-modal />
    <dispatch-modal />
    <gift-modal />
    <virtual-gift-modal />
    <!--<wmn-agreement v-if="agreementModal.open && sex.isWoman"></wmn-agreement>-->
    <low-credits />
    <app-alerts />
    <app-toast />
    <verification-modal />
    <questionnaire-modal />
    <chat-invites />
  </div>
</template>

<script>
import { NOTIFICATION_SOUND, MAIN_RESIZE_DETECTOR, GALLERY_INDEX } from '../../mixins/utils';
import { MAIL_MODAL } from '../../mixins/formsActions';
import VueGal from './VueGal.vue';
import SidebarProfile from '../Shared/Profile/Sidebar.vue';
import Toast from './Toast.vue';
import Alerts from './Alerts.vue';
import secure from '../../mixins/secureEnter';
import QuestionnaireModal from '../Shared/modals/QuestionnaireModal';
import MailModal from '../Shared/modals/WriteMailModal.vue';
import LowCredits from '../Shared/modals/LowCredits.vue';
import LetterModal from '../Shared/modals/GiftLetter.vue';
import DispatchModal from '../Shared/modals/DispatchModal.vue';
import GiftModal from '../Shared/modals/GiftModal.vue';
import VirtualGiftModal from '../Shared/modals/VirtualGiftModal';
import ChatInvites from '@/components/Authorized/ChatInvites';
import VerificationModal from '../Shared/modals/VerificationModal';

export default {
  components: {
    ChatInvites,
    VirtualGiftModal,
    SidebarProfile,
    appToast: Toast,
    appAlerts: Alerts,
    MailModal,
    LowCredits,
    VueGal,
    LetterModal,
    DispatchModal,
    GiftModal,
    VerificationModal,
    QuestionnaireModal,
  },
  mixins: [NOTIFICATION_SOUND, MAIN_RESIZE_DETECTOR, GALLERY_INDEX, MAIL_MODAL],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    chat() {
      return this.$store.getters.user.chat;
    },
    sex() {
      return this.$store.getters.sex;
    },
    agreementModal() {
      return this.$store.getters.wmnAgreementModal;
    },
  },

  watch: {
    chat(newVal, oldVal) {
      if (newVal !== oldVal && newVal === false) {
        this.regChat();
      }
    },
    /**
     * При переходе между страницами спрашивать данные о себе и зарегистрироваться в чате.
     * В противном случае - выйти на главную страницу
     */
    $route() {
      if (window.localStorage['access-token']) {
        this.$store.dispatch('getUserData');
        /* if (!this.chat) this.regChat() */
      } else {
        this.$router.push({ name: 'index' });
      }
    },
  },
  mounted() {
    this.regChat();
  },
  methods: {
    /**
     * Показать / спрятать сайдбар
     */
    toogleSidebar() {
      this.show = !this.show;
    },

    /**
     * Показать / спрятать сайдбар
     */
    closeSidebar() {
      this.show = false;
    },

    /**
     * Зарегистрироваться на чатсервере
     */
    regChat() {
      /* if (!this.chat) {
        this.$http
          .post('v1/chat/auth-new', {
            access_token: window.localStorage['access-token'],
            type: 'site',
          })
          .then((response) => {
            if (response.body.status) {
              console.log(response.body)
              this.$socket.emit(
                'registration',
                {
                  user_id: response.body.user_id,
                  front_v: this.$store.getters.frontendVersion,
                  type: 'site',
                },
                (data) => {
                  window.localStorage.setItem('chat-token', data.chatToken)
                  this.$store.commit('setRegisteredOnChatStatus', {
                    status: true,
                  })
                }
              )
            }
          })
      } */
    },
  },
  /**
   * При загрузке страницы проверить, можно ли пользователю показывать страницы зарегистрированного
   * пользователя
   */
  beforeRouteEnter(to, from, next) {
    secure.secureEnter(next);
  },
};
</script>
