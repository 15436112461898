/**
 * Created by Admin on 25.02.2017.
 */

export default {
  /**
	 * Проверка привилегий пользователя на получение запрашиваемого контента
	 *
	 * @param next
	 */
  secureEnter(next) {
    if (window.localStorage['access-token']) {
      next((vm) => {
        vm.$store.dispatch('getUserData');
      });
    } else {
      next({ name: 'index' });
    }
  },
};
