<template>
  <transition v-if="false" name="modal">
    <!--was modal.open-->
    <div class="vue-modal modal-mask dispatch-modal" tabindex="-1">
      <div class="vue-modal--wrapper">
        <div v-click-away="closeModal" class="vue-modal--container dispatch-modal--body">
          <div class="dispatch-modal--header set-flex">
            <h4 class="modal-title">
              <span class="icon"
                ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 559.372 559.372">
                  <path
                    d="M53.244.002c46.512 0 91.29 6.018 134.334 18.054s83.334 29.07 120.87 51.102c37.536 22.032 71.706 48.45 102.513 79.254 30.804 30.804 57.222 64.974 79.255 102.51 22.03 37.54 39.063 77.828 51.102 120.873 12.037 43.043 18.055 87.818 18.055 134.334 0 14.687-5.2 27.23-15.605 37.636-10.404 10.407-22.95 15.604-37.637 15.604-14.69 0-27.234-5.2-37.64-15.604-10.403-10.404-15.605-22.95-15.605-37.637 0-36.724-4.795-72.116-14.383-106.187-9.588-34.064-23.055-65.89-40.395-95.47-17.34-29.582-38.145-56.51-62.424-80.786-24.277-24.276-51.203-45.084-80.784-62.424-29.58-17.34-61.405-30.804-95.473-40.392s-69.462-14.38-106.182-14.38c-14.688 0-27.234-5.203-37.638-15.607S0 67.933 0 53.245 5.204 26.01 15.608 15.607C26.01 5.204 38.557.002 53.244.002zm0 201.348c42.024 0 81.498 8.058 118.422 24.174s69.156 37.944 96.696 65.484c27.54 27.54 49.37 59.77 65.484 96.693 16.117 36.93 24.174 76.4 24.174 118.427 0 14.688-5.2 27.23-15.604 37.637-10.404 10.404-22.95 15.604-37.64 15.604-14.69 0-27.234-5.2-37.638-15.604-10.404-10.404-15.606-22.95-15.606-37.637 0-27.338-5.202-53.04-15.606-77.113-10.404-24.072-24.582-45.084-42.534-63.035-17.952-17.954-38.964-32.132-63.036-42.536-24.072-10.402-49.776-15.604-77.112-15.604-14.688 0-27.234-5.2-37.638-15.605C5.202 281.83 0 269.285 0 254.595s5.202-27.233 15.606-37.637c10.404-10.406 22.95-15.608 37.638-15.608zm97.92 279.683c0 10.61-1.938 20.4-5.814 29.377-3.876 8.98-9.18 16.83-15.912 23.563-6.732 6.73-14.688 12.035-23.868 15.912-9.18 3.875-18.87 5.81-29.07 5.81-10.608 0-20.4-1.937-29.376-5.81-8.976-3.875-16.83-9.184-23.562-15.912-6.732-6.732-12.036-14.586-15.912-23.563-3.876-8.977-5.814-18.768-5.814-29.377 0-10.197 1.938-19.89 5.814-29.066 3.876-9.184 9.18-17.14 15.912-23.87 6.732-6.73 14.586-12.034 23.562-15.91 8.976-3.876 18.768-5.815 29.376-5.815 10.2 0 19.89 1.94 29.07 5.814 9.18 3.877 17.136 9.18 23.868 15.912 6.732 6.73 12.036 14.688 15.912 23.87 3.876 9.177 5.814 18.866 5.814 29.065z"
                  /></svg
              ></span>
              <span>
                {{ $t('mail.utils.dispatch') }}
              </span>
            </h4>
            <button type="button" class="close" @click="closeModal">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642">
                <path
                  fill-rule="evenodd"
                  d="M8.882 7.82l6.54-6.54c.294-.293.294-.768 0-1.06-.292-.294-.767-.294-1.06 0l-6.54 6.54L1.28.22C.987-.073.512-.073.22.22c-.294.293-.294.768 0 1.06l6.54 6.542-6.54 6.54c-.293.293-.293.768 0 1.06.147.147.338.22.53.22s.384-.072.53-.22l6.54-6.54 6.542 6.54c.147.147.338.22.53.22s.384-.072.53-.22c.293-.292.293-.767 0-1.06l-6.54-6.54z"
                />
              </svg>
            </button>
          </div>
          <div v-show="!!moderation_comment" class="dispatch-modal--error set-flex">
            <div class="icon">
              <i class="fa fa-exclamation-circle" aria-hidden="true" />
            </div>
            <div class="err">
              <h5 class="err--header">
                {{ $t('mail.dispatch.error') }}
              </h5>
              <p class="err--body">
                {{ moderation_comment }}
              </p>
            </div>
          </div>
          <div class="dispatch-modal--form">
            <form
              class="dispatch-modal--receivers"
              :class="{ active: filters.open }"
              @submit.prevent=""
            >
              <div
                class="dispatch-modal--receivers__header set-flex"
                @click="filters.open = !filters.open"
              >
                <p class="set-flex">
                  <strong>{{ $t('mail.utils.filter') }}</strong>
                  <span class="calc-state"><i class="fa fa-spinner fa-pulse fa-2x fa-fw" /></span>
                </p>
                <div class="chevron set-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.787 511.787">
                    <path
                      d="M508.667 125.707c-4.16-4.16-10.88-4.16-15.04 0L255.76 363.573 18 125.707c-4.267-4.053-10.987-3.947-15.04.213-3.947 4.16-3.947 10.667 0 14.827L248.293 386.08c4.16 4.16 10.88 4.16 15.04 0l245.333-245.333c4.16-4.054 4.16-10.88 0-15.04z"
                    />
                  </svg>
                </div>
              </div>
              <div class="dispatch-modal--receivers__body">
                <div class="filters form-group set-flex">
                  <div class="filters--item set-flex">
                    <div class="custom-checkbox" :class="{ disabled: settingBirthday }">
                      <div v-show="settingBirthday" @click="showAlert('online')">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.4 490.4">
                          <path
                            d="M99.15 147.2v51.2h-3.4c-21.5 0-38.9 17.4-38.9 38.9v214.2c0 21.5 17.4 38.9 38.9 38.9h298.9c21.5 0 38.9-17.4 38.9-38.9V237.3c0-21.5-17.4-38.9-38.9-38.9h-1v-51.2c0-81.2-66-147.2-147.2-147.2-81.3 0-147.3 66.1-147.3 147.2zm169 206.8c-3 2.2-3.8 4.4-3.8 7.8.1 15.7.1 31.4.1 47.1.3 6.5-3 12.9-8.8 15.9-13.8 7-27.5-2.8-27.5-15.8v-.1c0-15.8 0-31.5.1-47.3 0-3.2-.7-5.3-3.5-7.4-14.3-10.5-18.9-28.4-11.8-44.2 6.9-15.4 23.8-24.3 39.8-21.1 17.7 3.6 30.1 17.9 30.2 35.6.1 12.2-4.9 22.3-14.8 29.5zm-104.6-206.8c0-45.7 37.2-82.8 82.8-82.8 45.7 0 82.8 37.2 82.8 82.8v51.2h-165.6v-51.2z"
                          />
                        </svg>
                      </div>
                      <input id="dispatch-online" v-model="settingOnline" type="checkbox" />
                      <label for="dispatch-online" @click="showAlert('online')">{{
                        $t('mail.utils.online')
                      }}</label>
                    </div>
                    <div class="online" />
                  </div>
                  <div class="filters--item set-flex">
                    <div class="custom-checkbox">
                      <input id="dispatch-new" v-model="settingNew" type="checkbox" />
                      <label for="dispatch-new">{{ $t('mail.utils.new') }}</label>
                    </div>
                    <p class="new">New</p>
                  </div>
                  <div class="filters--item set-flex">
                    <div class="custom-checkbox" :class="{ disabled: settingOnline }">
                      <div v-show="settingOnline" @click="showAlert('birthday')">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.4 490.4">
                          <path
                            d="M99.15 147.2v51.2h-3.4c-21.5 0-38.9 17.4-38.9 38.9v214.2c0 21.5 17.4 38.9 38.9 38.9h298.9c21.5 0 38.9-17.4 38.9-38.9V237.3c0-21.5-17.4-38.9-38.9-38.9h-1v-51.2c0-81.2-66-147.2-147.2-147.2-81.3 0-147.3 66.1-147.3 147.2zm169 206.8c-3 2.2-3.8 4.4-3.8 7.8.1 15.7.1 31.4.1 47.1.3 6.5-3 12.9-8.8 15.9-13.8 7-27.5-2.8-27.5-15.8v-.1c0-15.8 0-31.5.1-47.3 0-3.2-.7-5.3-3.5-7.4-14.3-10.5-18.9-28.4-11.8-44.2 6.9-15.4 23.8-24.3 39.8-21.1 17.7 3.6 30.1 17.9 30.2 35.6.1 12.2-4.9 22.3-14.8 29.5zm-104.6-206.8c0-45.7 37.2-82.8 82.8-82.8 45.7 0 82.8 37.2 82.8 82.8v51.2h-165.6v-51.2z"
                          />
                        </svg>
                      </div>
                      <input id="dispatch-bd" v-model="settingBirthday" type="checkbox" />
                      <label for="dispatch-bd" @click="showAlert('birthday')">{{
                        $t('mail.utils.birthdays')
                      }}</label>
                    </div>
                    <div>
                      <img src="/static/images/balloons.png" alt="baloons" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <select v-model="receivers" class="form-control">
                    <option value="all">
                      {{ $t('mail.utils.all') }}
                    </option>
                    <option v-if="counters.favorites_all" value="favorites">
                      {{ $t('shared.common.favourites') }}
                    </option>
                    <option v-if="counters.bookmarks_all" value="bookmarks">
                      {{ $t('shared.common.bookmarks') }}
                    </option>
                  </select>
                  <div
                    v-if="!counters.bookmarks_all || !counters.favorites_all"
                    class="alert alert-warning"
                  >
                    <p v-if="!counters.bookmarks_all">* {{ $t('mail.dispatch.errNoBookmark') }}</p>
                    <p v-if="!counters.favorites_all">* {{ $t('mail.dispatch.errNoFavs') }}</p>
                  </div>
                </div>
                <div class="black-list">
                  <div class="d-flexblack-list--heading">
                    <div :class="{ 'has-error': err.first('modDispSearch') }">
                      <div class="set-flex">
                        <input
                          id="bllist"
                          v-model="searchValue"
                          type="number"
                          name="modDispSearch"
                          :placeholder="$t('mail.placeholders.searchById')"
                          class="form-control"
                        />
                      </div>
                      <div v-show="err.has('modDispSearch')" class="error">
                        <i class="fa fa-warning" />
                        <span>{{ err.first('modDispSearch') }}</span>
                      </div>
                    </div>
                    <label for="bllist"
                      ><strong>{{ $t('mail.utils.blackList') }}</strong> ({{ blackList.length }})
                    </label>
                  </div>
                  <div v-show="searchPreloader || noSearchResults" style="margin-top: 15px">
                    <div v-show="searchPreloader" class="loader--wrap">
                      <div class="loader" />
                    </div>
                    <p v-show="noSearchResults" class="alert alert-warning">
                      {{ $t('actionPages.errors.noSearchResults') }}
                    </p>
                  </div>
                  <div
                    v-show="displayedBlackList.length && !searchPreloader && !noSearchResults"
                    class="black-list--body"
                  >
                    <swiper :options="swiper">
                      <swiper-slide
                        v-for="(user, index) in displayedBlackList"
                        :key="user.user_id"
                        class="swiper-slide"
                      >
                        <div class="user-short">
                          <div class="user-short--ava">
                            <div
                              v-if="user.search"
                              class="d-flex align-items-center set-flex-x-center blcklst-actions blcklst-actions__add"
                              @mouseup="blackListAdd(user, index)"
                            >
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
                                  <path d="M42 19H23V0h-4v19H0v4h19v19h4V23h19" />
                                </svg>
                              </span>
                            </div>
                            <div
                              v-else
                              class="d-flex align-items-center set-flex-x-center blcklst-actions blcklst-actions__remove"
                              @mouseup="blackListRemove(user.user_id)"
                            >
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642">
                                  <path
                                    fill-rule="evenodd"
                                    d="M8.882 7.82l6.54-6.54c.294-.293.294-.768 0-1.06-.292-.294-.767-.294-1.06 0l-6.54 6.54L1.28.22C.987-.073.512-.073.22.22c-.294.293-.294.768 0 1.06l6.54 6.542-6.54 6.54c-.293.293-.293.768 0 1.06.147.147.338.22.53.22s.384-.072.53-.22l6.54-6.54 6.542 6.54c.147.147.338.22.53.22s.384-.072.53-.22c.293-.292.293-.767 0-1.06l-6.54-6.54z"
                                  />
                                </svg>
                              </span>
                            </div>
                            <img
                              :src="setPhotoSource(user.avatar.img_medium)"
                              :alt="user.name + $t('shared.alts.ava')"
                            />
                          </div>
                          <div class="user-short--info">
                            <p class="name">
                              {{ user.name }}
                            </p>
                            <p class="uid">ID: {{ user.user_id }}</p>
                          </div>
                        </div>
                      </swiper-slide>
                    </swiper>
                    <div slot="button-prev" class="button-next black-list--btn-direction hidden-xs">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.949 511.949">
                        <path
                          d="M386.235 248.308L140.902 2.975c-4.267-4.053-10.987-3.947-15.04.213-3.947 4.16-3.947 10.667 0 14.827l237.76 237.76-237.76 237.867c-4.267 4.053-4.373 10.88-.213 15.04 4.052 4.267 10.88 4.373 15.04.213l.212-.213L386.235 263.35c4.16-4.162 4.16-10.882 0-15.042z"
                        />
                      </svg>
                    </div>
                    <div slot="button-next" class="button-prev black-list--btn-direction hidden-xs">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.641 511.641">
                        <path
                          d="M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04-4.16-3.947-10.667-3.947-14.827 0L125.707 248.293c-4.16 4.16-4.16 10.88 0 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213 3.947-4.16 3.947-10.667 0-14.827L148.32 255.76z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <form class="dispatch-modal--form__static" @submit.prevent="submitDispatch">
              <div class="form-group" :class="{ 'has-error': err.first('modDispSubject') }">
                <input
                  v-model="subject"
                  type="text"
                  name="modDispSubject"
                  :placeholder="$t('mail.placeholders.subject')"
                  class="form-control"
                />
                <div v-show="err.has('modDispSubject')" class="error">
                  <i class="fa fa-warning" />
                  <span>{{ err.first('modDispSubject') }}</span>
                </div>
              </div>
              <div class="form-group" :class="{ 'has-error': err.first('modDispGreet') }">
                <div class="d-flexgreetings-row">
                  <input
                    v-model="greeting"
                    type="text"
                    name="modDispGreet"
                    :placeholder="$t('mail.placeholders.greetings')"
                    class="form-control"
                  />
                  <p><strong>John,</strong></p>
                </div>
                <div v-show="err.has('modDispGreet')" class="error">
                  <i class="fa fa-warning" />
                  <span>{{ err.first('modDispGreet') }}</span>
                </div>
              </div>
              <div class="form-group" :class="{ 'has-error': err.first('modDispText') }">
                <content-editable
                  v-model="text"
                  class="form-control"
                  :placeholder="$t('mail.placeholders.message')"
                  :name="'modDispText'"
                  @clearText="clearText = $event"
                />
                <p v-show="clearText" class="small text-muted">
                  {{ $t('mail.utils.symbCount', clearText.length) }}
                </p>
                <div v-show="err.has('modDispText')" class="error">
                  <i class="fa fa-warning" />
                  <span>{{ err.first('modDispText') }}</span>
                </div>
              </div>
              <div class="send-box d-flex align-items-center">
                <div class="d-flexset-flex-x-center set-flex-y-center">
                  <attach-box
                    :photos="attachments"
                    :user-id="selfId"
                    :clear="clear"
                    @attached="attachments = $event"
                  />
                  <button
                    v-if="update.status"
                    type="button"
                    class="btn btn-danger btn-delete"
                    @mouseup="deleteMail"
                  >
                    {{ $t('mail.utils.delete') }}
                  </button>
                  <button type="submit" class="btn btn-primary mail-submit">
                    {{ $t('mail.utils.send') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import debounce from 'lodash/debounce';
import { Validator } from 'vee-validate';
import { PHOTO_SRC } from '../../../mixins/utils';
import { CLEAN_HTML } from '../../../mixins/types/mail';
import { DISPATCH_MODAL } from '../../../mixins/formsActions';
import ContentEditable from '../../Authorized/parts/ContentEditable.vue';
import AttachBox from '../parts/AttachPhotosBox.vue';

export default {
  validator: null,
  data() {
    return {
      err: [],
      update: {
        status: false,
        distId: 0,
      },
      filters: {
        open: false,
      },
      swiper: {
        slidesPerView: 6,
        spaceBetween: 15,
        nextButton: '.button-next',
        prevButton: '.button-prev',
        paginationClickable: true,
        mousewheelControl: true,
        touchEventsTarget: 'wrapper',
        touchReleaseOnEdges: true,
        simulateTouch: false,
        breakpoints: {
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
          },
        },
      },
      subject: '',
      searchValue: '',
      searchResult: [],
      noSearchResults: false,
      searchPreloader: false,
      receivers: 'all',
      text: '',
      clearText: '',
      greeting: '',
      settingOnline: 0,
      settingNew: 0,
      settingBirthday: 0,
      blackList: [],
      clear: 0,
      moderation_comment: '',
      attachments: [],
    };
  },
  computed: {
    modal() {
      return this.$store.getters.dispatchModal;
    },

    /**
     * Форматирование получателей в соответствии с требованиями input'ов
     *
     * @return {{bookmarks: number, favorites: number}}
     */
    receiversFormatted() {
      return {
        bookmarks: this.receivers === 'bookmarks' ? 1 : 0,
        favorites: this.receivers === 'favorites' ? 1 : 0,
      };
    },
    selfId() {
      return this.$store.getters.user.user_id;
    },

    /**
     * Получить отображаемый список игнорируемых в зависимости от режима поиска
     *
     * @return {Array}
     */
    displayedBlackList() {
      return this.searchResult.length ? this.searchResult : this.blackList;
    },

    /**
     * Получить только ИД пользователей из списка игнорируемых
     *
     * @return {Array}
     */
    blackListIds() {
      return this.blackList.map((i) => i.user_id);
    },

    /**
     * Форматируем список аттачей для отправки
     *
     */
    formattedAttach() {
      return this.attachments.map((i) => ({ id: i.id }));
    },

    /**
     * Собрать объект для отправки на сервер
     *
     * @return {{access_token: String, subject: String, text: String, greeting: String, setting_online: number, setting_new: number, setting_birthday: number,
     * 			setting_favorite, setting_bookmark, black_list_user_id: string, attachments: string}}
     */
    dispatchData() {
      return {
        access_token: window.localStorage['access-token'],
        subject: this.subject,
        text: this.text,
        greeting: this.greeting,
        setting_online: this.settingOnline ? 1 : 0,
        setting_new: this.settingNew ? 1 : 0,
        setting_birthday: this.settingFavorite ? 1 : 0,
        setting_favorite: this.receiversFormatted.favorites,
        setting_bookmark: this.receiversFormatted.bookmarks,
        black_list_user_id: JSON.stringify(this.blackListIds),
        attachments: JSON.stringify(this.formattedAttach) || JSON.stringify([]),
      };
    },
    counters() {
      return this.$store.getters.counters;
    },
  },
  watch: {
    /**
     * Валидация строки поиска и запуск поиска
     *
     * @param v {string}
     */
    searchValue(v) {
      this.validator.validate('modDispSearch', +v).catch(() => {});
      if (v) {
        this.searchResult = [];
        this.searchPreloader = true;
        this.search(v);
      } else {
        this.searchResult = [];
        this.searchPreloader = false;
      }
      this.noSearchResults = false;
    },

    /**
     * Валидация заголовка письма
     *
     * @param v {string}
     */
    subject(v) {
      this.validator.validate('modDispSubject', v).catch(() => {});
    },

    /**
     * Валидация приветствия письма
     *
     * @param v {string}
     */
    greeting(v) {
      this.validator.validate('modDispGreet', v).catch(() => {});
    },

    /**
     * Валидация текста письма
     *
     * @param v {string}
     */
    clearText(v) {
      this.validator.validate('modDispText', v).catch(() => {});
    },

    /**
     * Отключать настройку "онлайн", если settingBirthday == true
     *
     * @param v
     */
    settingOnline() {
      if (this.settingBirthday) {
        this.settingOnline = false;
      }
    },

    /**
     * Отключать настройку "день рожденья", если settingOnline == true
     *
     * @param v
     */
    settingBirthday() {
      if (this.settingOnline) {
        this.settingBirthday = false;
      }
    },
  },
  methods: {
    /**
     * Показывать ли уведомление об отметке день рожденье + онлайн вместе
     *
     * @param e {string}
     */
    showAlert(e) {
      if ((e === 'online' && this.settingBirthday) || (e === 'birthday' && this.settingOnline)) {
        this.$store.commit('addAlert', {
          type: 'error',
          text: this.$t('mail.dispatch.cantDesc'),
        });
      }
    },

    /**
     * Записать данные в состояние. ( При редактировании рассылки)
     *
     */
    setData() {
      if (this.modal.data.message) {
        const { data } = this.modal;
        this.update.status = this.modal.data.status !== 'reject';
        this.update.distId = data.message.id;
        this.subject = data.message.subject;
        this.text = data.message.text.trim();
        this.moderation_comment = data.moderation_comment;
        this.greeting = data.message.greeting.trim();
        this.settingOnline = data.message.setting_online || 0;
        this.settingNew = data.message.setting_new || 0;
        this.settingBirthday = data.message.setting_birthday || 0;
        this.attachments =
          data.attachments.map((i) => ({
            _photo: i.file_path,
            id: i.id,
          })) || [];
        this.setBlackList(data.black_list);
        this.$store.commit('updateDispatchModal', {
          data: {},
        });
      }
    },

    /**
     * Заполнить игнорлист данными
     *
     * @param arr {array}
     */
    setBlackList(arr) {
      arr.forEach((i) => {
        this.searchUser(i.user_id).then((r) => {
          this.blackList.push(r[0]);
        });
      });
    },

    /**
     * Закрыть модалку и очистить состояние
     *
     */
    closeModal() {
      this.toggleDispatchModal();
      this.clearData();
    },

    /**
     * Удалить письмо
     */
    deleteMail() {
      this.$http
        .post('v1/message/distribution/delete', {
          access_token: window.localStorage['access-token'],
          distribution_id: this.update.distId,
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.updateDispatchList();
              this.closeModal();
              this.$store.commit('addAlert', {
                type: 'success',
                text: this.$t('alerts.dispatchDelSuccess'),
              });
              this.$router.push({ name: 'mail-invitations' });
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
          }
        );
    },

    /**
     * Создать рассылку
     */
    createDispatch() {
      this.$http.post('v1/message/distribution/new', this.dispatchData).then(
        (r) => {
          if (r.body.status) {
            this.updateDispatchList();
            this.$store.commit('addAlert', {
              type: 'success',
              text: this.$t('alerts.dispatchSuccess'),
            });
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
          }
        },
        () => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.someErr'),
          });
        }
      );
    },
    /**
     * Редактировать рассылку
     *
     */
    editDispatch() {
      const tmp = { ...this.dispatchData };
      tmp.distribution_id = this.update.distId;
      this.$http.post('v1/message/distribution/update', tmp).then(
        (r) => {
          if (r.body.status) {
            this.updateDispatchList();
            this.$store.commit('addAlert', {
              type: 'success',
              text: this.$t('alerts.dispatchEditSuccess'),
            });
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
          }
        },
        () => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.someErr'),
          });
        }
      );
    },
    updateDispatchList() {
      this.$store.dispatch('getDispatch');
    },
    /**
     * Отправить запрос на редактирование или создание рассылки
     * с Валидацией даннных перед этим
     *
     */
    submitDispatch() {
      this.validator
        .validateAll({
          modDispSubject: this.subject,
          modDispGreet: this.greeting,
          modDispText: this.clearText,
        })
        .then((success) => {
          if (success) {
            this.update.status ? this.editDispatch() : this.createDispatch();
            this.closeModal();
          }
        })
        .catch(() => {});
    },
    /**
     * Поиск пользователя по ИД
     *
     * @param id {number} ИД пользователя
     * @return {Promise}
     */
    searchUser(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('v1/profile/profile', {
            access_token: window.localStorage['access-token'],
            target_user_id: id,
          })
          .then(
            (response) => {
              if (response.body.status && response.body.result.user_id !== this.selfId) {
                resolve(response.body.result);
              } else {
                reject();
              }
            },
            () => reject()
          );
      });
    },
    /**
     * Поиск пользователя по ИД
     * @param id {number}
     */
    searchNew(id) {
      const self = this;
      const tmp = id;
      this.searchUser(tmp)
        .then((r) => {
          self.searchPreloader = false;
          const record = {
            avatar: {
              img_medium: r.avatar.img_medium,
            },
            user_id: r.user_id,
            search: true,
            name: r.name,
          };
          self.searchResult.push(record);
        })
        .catch(() => {
          self.searchPreloader = false;
          if (self.searchValue) self.noSearchResults = true;
        });
    },
    /**
     * Поиск пользователя среди существующих
     * @param id {number} ид пользователя
     * @return {array}
     */
    searchExist(id) {
      return this.blackList.find((i) => i.user_id === +id);
    },
    /**
     * запускает поиск по ИД. Либо среди сущесвутвующих, либо с загрузкой новых профилей
     *
     * @param id {number} ид пользователя
     */
    search: debounce(function (id) {
      if (id) {
        const exist = this.searchExist(id);
        if (exist) {
          this.searchPreloader = false;
          this.searchResult.push(exist);
        } else {
          this.searchNew(id);
        }
      }
    }, 300),
    /**
     * добавить в блек лист
     *
     * @param user {object} объект с пользователем
     * @param index {index} индекс элемента
     */
    blackListAdd(user, index) {
      user.search = false;
      const match = this.blackList.find((i) => i.user_id === user.user_id);
      if (!match) this.blackList.push(user);
      this.searchResult.splice(index, 1);
      this.searchValue = '';
      const self = this;
      setTimeout(() => {
        self.err.clear();
      });
    },
    /**
     * убрать с блек листа пользователя по ИД
     *
     * @param id {number} ИД пользователя
     */
    blackListRemove(id) {
      const index = this.blackList.findIndex((i) => i.user_id === id);
      this.blackList.splice(index, 1);
      this.searchValue = '';
      const self = this;
      setTimeout(() => {
        self.err.clear();
      });
    },
    /**
     * Очистка состояния компонента
     */
    clearData() {
      this.subject = '';
      this.searchValue = '';
      this.searchResult = [];
      this.receivers = 'all';
      this.text = '';
      this.clearText = '';
      this.greeting = '';
      this.settingOnline = 0;
      this.settingNew = 0;
      this.settingBirthday = 0;
      this.blackList = [];
      this.moderation_comment = '';
      this.attachments = [];
      this.clear++;
      this.$store.commit('updateDispatchModal', {
        data: {},
      });
      this.validator.errorBag.clear();
    },
  },
  components: {
    AttachBox,
    ContentEditable,
  },
  mixins: [PHOTO_SRC, DISPATCH_MODAL, CLEAN_HTML],
  created() {
    /**
     * Инициализация плагина с валидацией
     * @type {Validator}
     */
    this.validator = new Validator();
    this.validator.attach('modDispSubject', 'required|min:10|max:255', {
      prettyName: this.$t('mail.placeholders.subject'),
    });
    this.validator.attach('modDispGreet', 'required|max:255', {
      prettyName: this.$t('mail.placeholders.greetings'),
    });
    this.validator.attach('modDispText', 'required|min:200|max:5000', {
      prettyName: this.$t('mail.placeholders.message'),
    });
    this.validator.attach('modDispSearch', 'numeric|min_value:0', {
      prettyName: this.$t('mail.placeholders.message'),
    });
    this.$set(this, 'err', this.validator.errorBag);
  },
  updated() {
    if (!this.modal.open) {
      this.validator.errorBag.clear();
    }
    setTimeout(() => {
      this.setData();
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.dispatch-modal {
  color: #3e3f43;

  $padding-x: 20px;

  &--body {
    width: 600px;
  }

  .vue-modal--container {
    padding: 0 0 20px;
  }

  &--header {
    padding: 20px $padding-x;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    background-color: #f2f2f2;

    .modal-title {
      font-weight: bold;
      font-size: 18px;
    }

    svg {
      height: 16px;
      width: 16px;
      fill: #b0b0b1;
    }
  }

  .blcklst-actions {
    position: absolute;
    top: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    line-height: 0;
    color: #ffffff;
    cursor: pointer;
    user-select: none;

    svg {
      height: 10px;
      width: 10px;
      fill: currentColor;
    }

    &__add {
      left: 0;
      background-color: $brand-primary;
    }

    &__remove {
      right: 0;
      background-color: rgb(63, 63, 67);
    }
  }

  &--error {
    align-items: flex-start;
    padding: 15px $padding-x 20px;
    background-color: #e25746;
    color: #fff;

    .icon {
      font-size: 30px;
      line-height: 1;
    }

    .err {
      margin-left: 15px;

      &--header {
        font-weight: bold;
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
  }

  &--receivers {
    &.active {
      .dispatch-modal--receivers__body {
        display: block;
      }

      .calc-state {
        display: block;
      }

      .info-state {
        display: none;
      }

      .chevron {
        svg {
          transform: scaleY(-1);
        }
      }
    }

    .calc-state {
      color: #b2b2b2;
      display: none;
    }

    &__header {
      position: relative;
      padding: 10px $padding-x;
      justify-content: space-between;
      align-items: center;
      line-height: 1;
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.16);
      cursor: pointer;

      .info-state {
        margin-left: 5px;
        text-decoration: underline;
        color: $brand-primary;
      }

      .chevron {
        align-items: center;
      }

      > p {
        align-items: center;
      }

      svg {
        fill: $brand-primary;
        height: 16px;
        width: 16px;
      }
    }

    &__body {
      display: none;
      padding: 10px $padding-x 20px;
      background-color: #f5f5f5;

      .alert {
        margin-top: 10px;
      }

      .filters {
        .filters--item + .filters--item {
          margin-left: 20px;
        }
        .custom-checkbox [type='checkbox']:not(:checked) + label:before {
          background-color: #fff;
        }

        &--item {
          align-items: center;

          .custom-checkbox {
            margin-right: 5px;
          }
        }

        .online {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background: #05ca6b;
        }

        .new {
          border-radius: 8px;
          border: 2px solid #ff4614;
          text-transform: uppercase;
          font-size: 9px;
          font-weight: bold;
          padding: 2px 3px;
          line-height: 1;
          color: #ff4614;
          background: #fff;
        }
      }

      .black-list {
        position: relative;

        .has-error {
          color: #d9534f;

          .error {
            margin-top: 5px;
          }
        }

        &--heading {
          justify-content: space-between;
          align-items: center;

          .set-flex {
            position: relative;
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: calc(100% + 10px);
              height: 1px;
              width: calc(560px - 186px);
              background-color: #a9a9a9;
              transform: translateY(-50%);
            }
          }

          label {
            margin: 0;
            position: relative;
            background-color: #f5f5f5;
            padding-left: 10px;
          }
        }

        &--body {
          position: relative;
          padding: 0 25px;
          margin-top: 10px;
        }

        &--btn-direction {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          &.button-next {
            left: auto;
            right: 0;
          }

          svg {
            width: 16px;
            height: 17px;
            fill: #b0b0b1;
          }

          &:active {
            svg {
              fill: $brand-primary;
            }
          }
        }
      }
    }
  }

  .btn-danger {
    color: #e25746;
    background: none;
    border-color: #e25746;
  }

  .user-short {
    &--ava {
      position: relative;
      max-width: 80px;
      margin-bottom: 5px;
      img {
        border-radius: 50%;
        width: 100%;
      }
    }

    &--info {
      text-align: center;
      .name {
        width: 80px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #3e3f42;
        text-decoration: underline;
      }
      .uid {
        width: 80px;
        color: #bfc1c6;
      }
    }
  }

  &--form {
    &__static {
      padding: 15px $padding-x;

      .greetings-row {
        align-items: center;

        p {
          margin-left: 15px;
          font-size: 18px;
          line-height: 1;
        }
      }
    }

    .form-control {
      border-radius: 4px;
      box-shadow: none;
      min-height: 36px;
    }

    textarea.form-control {
      resize: none;
      min-height: 100px;
    }
  }

  .send-box {
    justify-content: flex-end;

    .btn {
      line-height: 1;
      font-size: 11px;
      padding: 10px 20px;
      text-transform: uppercase;
      margin-left: 15px;
    }
  }

  .custom-checkbox {
    svg {
      height: 20px;
      width: 20px;
      fill: #bababa;
    }
    &.disabled {
      display: flex;
      align-items: center;
      label {
        padding-left: 0 !important;
        margin-left: 10px;
        &:before,
        &:after {
          display: none !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .dispatch-modal {
    border-radius: 0;

    &--body {
      width: auto;
    }

    &--header {
      border-radius: 0;
    }

    &--receivers {
      &__body {
        .filters {
          flex-wrap: wrap;
          margin: 0 -5px 10px;
          justify-content: space-between;

          &--item {
            margin: 5px 5px 0 !important;
          }
        }
      }
    }

    .black-list {
      &--heading {
        flex-wrap: wrap;

        > div {
          flex: 0 0 100%;
        }

        .set-flex {
          &:before {
            display: none;
          }
        }

        label {
          margin-top: 5px;
          padding-left: 0;
        }
      }
    }

    .send-box {
      &--with-attach {
        flex-direction: row;

        .btn {
          margin-top: 0;
          margin-left: 15px;
        }

        > div {
          flex-direction: row;
        }
      }
    }
  }
}
</style>
