<template>
  <transition name="modal">
    <div
      v-if="modal.open"
      class="vue-modal modal-mask gift-letter"
      @click="closeModal($event.target)"
    >
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container gift-letter--body">
          <div>
            <button type="button" class="close" @click="closeModal(false)">
              <span class="rbi rbi-close" />
            </button>
            <h4 class="modal-title">Letter from {{ user.name }}</h4>
          </div>
          <hr />
          <div class="modal-body">
            {{ modal.text }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { PHOTO_SRC } from '../../../mixins/utils';

export default {
  mixins: [PHOTO_SRC],
  data() {
    return {};
  },
  computed: {
    modal() {
      return this.$store.getters.giftLetterModal;
    },
    user() {
      return this.modal.user;
    },
  },
  methods: {
    /**
     * закрыть модалку
     * @param e {objtct} - event клика
     */
    closeModal(e) {
      // eslint-disable-next-line no-undef
      if (!$(e).closest('.gift-letter--body').length || !e) {
        this.clearData();
      }
    },
    /**
     * очистить состояние компонента
     */
    clearData() {
      this.$store.commit('updateGiftLetterModal', {
        open: false,
        user: { avatar: {} },
        text: '',
      });
    },
  },
};
</script>

<style lang="scss">
.gift-letter {
  .modal-body {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .gift-letter {
    &--body {
      padding: 15px;
      width: 100%;
    }
  }
}
</style>
